<template>
    <div class="item_radio_pic" :class="itemRadioBoxPicClass">
        <div
            v-for="(pic, index) in radioPicSetting.picList"
            :key="pic.id"
            class="radio_pic_item g_main_color_v3 g_oldThemeColor"
            :class="{ radio_pic_item_checked: selectId === pic.id }"
            :style="itemStyle"
            @click="selectItem(pic)"
        >
            <div
                class="radio_pic_item_pic"
                :style="getStyle(pic)"
                @[previewPicEvent].stop="previewPic(pic, index)"
            ></div>
            <div v-if="radioPicSetting.isShowPhotoName" :style="itemNameStyle" class="radio_pic_item_name">{{
                pic.name
            }}</div>
        </div>
    </div>
</template>
<script>
import Mixins from '../mixins';
import { slideshowView } from '@/shared/photo/slides.js';
import { encodeHtml } from '@/shared/util';
import { getFontStyleObject } from '../utils';
import formDetailEditMixin from '../mixins/formDetailEdit.js';

export default {
    name: 'FormSinglePhoto',
    mixins: [Mixins, formDetailEditMixin],
    inject: ['module', 'options'],
    props: {
        formItem: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            selectId: '',
        };
    },
    computed: {
        itemCount() {
            return this.arrangeStyle === 1 ? 1 : 3;
        },
        arrangeStyle() {
            return this.module.content.od;
        },
        radioPicSetting() {
            return this.formItem.radioPicSetting;
        },
        itemRadioBoxPicClass() {
            return this.arrangeStyle === 1 ? 'item_radio_pic_vertical' : '';
        },
        itemStyle() {
            return {
                ...{
                    width: `calc(calc(100% - ${(this.itemCount - 1) * 0.4}rem) / ${this.itemCount}) `,
                },
            };
        },
        submitVal() {
            return this.radioPicSetting.picList.filter((pic) => pic.id === this.selectId);
        },
        fontStyle() {
            return getFontStyleObject(this.module.content.mhighs.oin);
        },
        itemNameStyle() {
            return {
                ...this.picNameStyle,
                ...this.fontStyle,
            };
        },
        picClickEffect() {
            return this.picSize.c;
        },
        previewPicEvent() {
            return this.picClickEffect === 0 ? 'click' : null;
        },
    },
    mounted() {
        this.initDefaultValue();
    },
    methods: {
        getStyle(item) {
            return {
                ...this.picSizeStyle,
                ...this.picScaleStyle,
                backgroundImage: `url(${item.src})`,
            };
        },
        selectItem(pic) {
            this.selectId = this.selectId === pic.id ? '' : pic.id;
        },
        previewPic(pic, index) {
            const initialIndex = index;
            slideshowView({
                id: pic.id,
                previewSrcList: this.radioPicSetting.picList.map((item) => ({
                    src: item.src,
                    title: '',
                    description: '',
                })),
                initialIndex,
            });
        },
        validate() {
            if (this.formItem.must && this.selectId === '') {
                this.$mobiIng(jm.format(LS.siteFormSubmitInputIsEmpty, encodeHtml(this.formItem.name)));
                return false;
            }
            return true;
        },
        initDefaultValue() {
            const initialValue = this.inMemberDetailEdit ? this.getEditingItemSubmitValue() : '';
            if (typeof initialValue === 'string' && initialValue.length > 0) {
                try {
                    const selectedItem = JSON.parse(initialValue)[0];
                    if (selectedItem && selectedItem.id) {
                        this.selectId = selectedItem.id;
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.item_radio_pic {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
}
.radio_pic_item {
    cursor: pointer;
    border: 1px solid #e3e2e8;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    padding: 0.3rem;
    box-sizing: border-box;
    &_pic {
        border-radius: 0.1rem;
        width: 100%;
        height: 4rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &_name {
        margin-top: 0.2rem;
        color: #333;
        font-size: 0.6rem;
    }

    &_checked {
        border: 1px solid currentColor;
    }
}

.item_radio_pic_vertical {
    .radio_pic_item {
        flex-direction: row;
        justify-content: flex-start;
        box-sizing: border-box;
        &_pic {
            margin-right: 0.4rem;
            width: 3.5rem;
            height: 3.5rem;
            flex-shrink: 0;
        }
    }
}

//
.item .radio_pic_item {
    border: none;
    background-color: #f6f6f6;
    width: calc((100% - 0.8rem) / 3);
    &_pic {
        width: 4rem;
    }
}
.item .radio_pic_item_name {
    font-size: 0.6rem;
    color: #666;
    max-width: 4rem;
}

.mobi_form_style_1 {
    .radio_pic_item {
        border: 1px solid transparent;
        background-color: #f6f6f6;
        &_checked {
            border: 1px solid currentColor;
        }
    }
}

.mobi_form_style_2,
.mobi_form_style_3 {
    .radio_pic_item {
        background-color: #fff;
    }
}
.mobi_form_style_3 {
    .item_radio_pic_vertical {
        margin-top: 0.35rem;
    }
}
</style>
