<template>
    <module-frame :module-id="moduleId">
        <div v-if="render" class="J_tabModule fk-formTab formTab2" :class="tabStyleClass" :_arrowOpen="showArrow">
            <div class="J_formTabModuleContent f-formTabModuleContent" :class="wrapOpenCls">
                <div class="J_formTabButtonWrap f-formTabButtonWrap">
                    <div class="J_formTabButtonList f-formTabButtonList" :style="buttonListStyle">
                        <div
                            v-for="(item, index) in labelList"
                            :id="'formTabButton' + item.id"
                            :key="'label_item_' + index"
                            :class="formTabButtonClass(index, item.id)"
                            class="J_formTabButton f-formTabButton g_label_title"
                            :tabModuleId="item.id"
                            @click="tabButtonClickHanle(index, $event)"
                        >
                            <div
                                class="J_formTabMiddle f-formTabMiddle"
                                :class="formTabMiddleClass(index)"
                                :style="formTabMiddleStyle(index)"
                            >
                                <div class="f-formTabMiddleBox">
                                    <span
                                        v-if="buttonTextRender"
                                        class="f-formTabButtonText"
                                        :style="label_button_style(index)"
                                        >{{ itemLabelName(item.labelName, item.id) }}</span
                                    >
                                    <span v-if="showUnderLine(index)" class="f-formTabButtonUnderLine">
                                        <span
                                            class="buttonLine"
                                            :class="index == currentIndex ? 'g_main_bdColor_v3 g_oldThemeBdColor' : ''"
                                            :style="bottomLineStyle(index)"
                                        ></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <span v-if="showDefaultLine" class="style2DefaultLine g_label_deFault_label">
                            <span class="buttonLine" :style="defaultLineStyle"></span>
                        </span>
                    </div>
                </div>
            </div>
            <div
                :id="'formTabContentList' + id"
                class="J_formTabContentList f-formTabContentList column"
                :class="'formTabContentList' + id"
                :style="formTabContentListStyle"
            >
                <div ref="tabContentBox" class="tabContentBox">
                    <template v-for="(item, index) in labelList">
                        <div
                            v-if="item.id < 0"
                            :id="'formTabContent' + item.id"
                            :key="'formTabContent_' + index"
                            class="J_formTabContent"
                            :class="formTabContentClass(index)"
                            :styleId="item.style"
                        >
                            <template v-if="manageMode">
                                <div class="empty_tips_panel">
                                    <div class="text">请将模块拖入标签区域</div>
                                    <button
                                        class="jz_button jz_button__active addBtn_Icon"
                                        @click.stop="addModuleToTab(item.id)"
                                        >添加模块</button
                                    >
                                </div>
                            </template>
                            <template v-else>
                                <div class="cus_Empty_tips_panel">
                                    <div class="text">没有添加内容</div>
                                </div>
                            </template>
                        </div>
                        <template v-else>
                            <tab-child-module
                                v-if="labelCache['module' + item.id]"
                                :id="item.id"
                                :key="'tab_child_module_' + index"
                                :class="formTabContentClass(index)"
                                :style-id="item.style"
                                :item-data="labelCache['module' + item.id]"
                                :label-data="item"
                                :manage-mode="manageMode"
                            ></tab-child-module>
                        </template>
                    </template>
                </div>
                <module-loading v-if="showLoading"></module-loading>
            </div>
        </div>
    </module-frame>
</template>

<script>
import { ing } from '@/shared/tips';
import { mapActions, mapGetters } from 'vuex';

import { removeJumpModuleInfo, bindJumpModuleEvent, getJumpModuleCookieByType, TAB_MODULE_ID } from '../../comm';
import { inTabModuleEdit, initTabModuleStyle } from '../util';
import { getChildModuleData } from '@/api/module/tabContainer';

import TabChildModule from './components/TabChildModule.vue';
import ModuleLoading from './components/ModuleLoading.vue';
import ModuleFrame from '@/modules/frame/index.vue';
import { getQurl } from '@/manage/url';
import { setModuleHeight2 } from '@/manage/utils/module';
import { NEW_MANAGE_HTML_FONTSIZE } from '@/shared/constants';
import { initModuleAttr } from '@/store/util';

const manageMode = VITE_APP_MODE !== 'visitor';
export default {
    name: 'TabContainer',
    components: {
        TabChildModule,
        ModuleLoading,
        ModuleFrame,
    },
    props: {
        moduleId: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            contentHeight: 'auto',
            labelCache: {},
            showLoading: false,
            doAnimateTimer: null,
            buttonTextRender: true,
            manageMode,
            curIdx: 0,
            render: true,
        };
    },
    computed: {
        ...mapGetters(['getModuleById']),
        module() {
            return this.getModuleById(this.moduleId);
        },
        options() {
            return this.module.renderOptions;
        },
        currentIndex: {
            get() {
                /*计算属性的结果会被缓存，除非依赖的响应式 property 变化才会重新计算。
                注意，如果某个依赖 (比如非响应式 property) 在该实例范畴之外，则计算属性是不会被更新的。
                因此强行绑定一个curIdx依赖触发curIndex更新 1080420
                */
                this.curIdx;
                return this.module.extInfo.currentIndex || 0;
            },
            set(val) {
                this.curIdx = val;
                this.module.extInfo.currentIndex = val;
            },
        },
        id() {
            return this.moduleId;
        },
        advance() {
            return this.module.content.mhighs;
        },
        styleId() {
            return this.module.style;
        },
        tabStyle() {
            return this.module.content.tabStyle;
        },
        nlms() {
            return this.module.content.nlms;
        },
        chooseColor() {
            return this.module.content.color.cValue;
        },
        wrapOpenCls() {
            if (this.wrapOpen) {
                return 'wrapOpen';
            } else {
                return '';
            }
        },
        showDefaultLine() {
            var defaultUnderLine = this.nlms.hld.lu;
            if (this.tabStyle == 2 && this.nlms.hll != 0 && defaultUnderLine.t != 1 && this.nlms.hlus == 0) {
                return true;
            }
            return false;
        },
        defaultLineStyle() {
            if (!manageMode) {
                return '';
            }
            var style = {};
            var underLineData = this.nlms.hld.lu;
            if (this.tabStyle == 2 && underLineData) {
                if (underLineData.t == 2) {
                    if (underLineData.w) {
                        style['border-bottom-width'] = underLineData.w + 'rem';
                    } else {
                        style['border-bottom-width'] = '1px';
                    }
                    if (underLineData.s == 0) {
                        style['border-bottom-style'] = 'solid';
                    } else if (underLineData.s == 1) {
                        style['border-bottom-style'] = 'dotted';
                    } else if (underLineData.s == 2) {
                        style['border-bottom-style'] = 'dashed';
                    }
                    style['border-bottom-color'] = underLineData.c;
                }
            }
            return style;
        },
        tabStyleClass() {
            var cls = '';
            cls += 'fk-tabStyle' + this.tabStyle;
            cls += ' labelLayout' + (this.labelLayout + 1);

            if (this.tabStyle == 2) {
                if (this.nlms.hlus == 0) {
                    cls += ' longLine';
                } else if (this.nlms.hlus == 1) {
                    cls += ' shortLine';
                }
            }
            return cls;
        },
        buttonListStyle() {
            if (!manageMode || this.tabStyle != 4) {
                return '';
            }
            var style = {},
                defaultBgData = this.nlms.hld.lbg;

            if (defaultBgData && defaultBgData.t == 1) {
                style['background-color'] = defaultBgData.c;
            }

            if (this.nlms.hlms.t == 1 && (this.nlms.hlms.h > 0 || this.nlms.hlms.v > 0)) {
                style['background-color'] = 'unset';
            }
            return style;
        },
        moduleIdList() {
            return this.module.content.moduleList;
        },
        wrapOpen() {
            return this.tabStyle != 4 && this.nlms.hll == 0 && this.module.content.wrapOpen;
        },
        arrowOpen() {
            return this.module.content.arrowOpen || false;
        },
        showArrow() {
            return !this.wrapOpen && this.arrowOpen;
        },
        labelList() {
            if (this.nlms.hll == 2) {
                var labelList = JSON.parse(JSON.stringify(this.module.content.labelList));
                return labelList.slice(0, 5);
            } else {
                return this.module.content.labelList;
            }
        },
        labelLayout() {
            return this.nlms.hll;
        },
        formTabContentListStyle() {
            return {
                height: `${this.contentHeight}`,
                position: `relative`,
            };
        },
    },
    watch: {
        'module.style': {
            handler: function () {
                if (VITE_APP_MODE === 'visitor') {
                    return;
                }
                this.$destroy();
            },
        },
        'module.content.labelList': {
            handler: function () {
                if (VITE_APP_MODE === 'visitor') {
                    return;
                }
                this.setContentHeight();
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                    this.$nextTick(() => {
                        bindEvent(this);
                        if (this.module.content.labelList[this.currentIndex]) {
                            this.showTab(this.currentIndex);
                        } else {
                            this.currentIndex = 0;
                            this.showTab(this.currentIndex);
                        }
                    });
                });
            },
            deep: true,
        },
        'module.content.wrapOpen': {
            handler: function () {
                if (VITE_APP_MODE === 'visitor') {
                    return;
                }
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                    this.$nextTick(() => {
                        bindEvent(this);
                    });
                });
            },
        },
        'module.content.tabStyle': {
            handler: function () {
                if (VITE_APP_MODE === 'visitor') {
                    return;
                }
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                    this.$nextTick(() => {
                        bindEvent(this);
                    });
                });
            },
        },
        'module.content.nlms': {
            handler: function () {
                if (VITE_APP_MODE === 'visitor') {
                    return;
                }
                this.render = false;
                this.$nextTick(() => {
                    this.render = true;
                    this.$nextTick(() => {
                        bindEvent(this);
                    });
                });
            },
            deep: true,
        },
    },
    mounted() {
        console.log('mounted');
        bindEvent(this);
        // 获取 cookie 中当前选中的下标id
        if (!manageMode) {
            let cacheTabModuleId = parseInt(getJumpModuleCookieByType(this.moduleId, TAB_MODULE_ID));
            if (cacheTabModuleId != null && cacheTabModuleId != '') {
                let cacheModuleIndex = this.moduleIdList.findIndex((id) => {
                    return id == cacheTabModuleId;
                });
                if (cacheModuleIndex > -1 && this.module.extInfo.currentIndex != cacheModuleIndex) {
                    // 纠正 web 缓存没有进入jsp逻辑，导致返回优化不准确问题
                    this.module.extInfo.currentIndex = cacheModuleIndex;
                }
            }
        }

        this.currentIndex = this.module.extInfo.currentIndex || 0;
        this.labelCache = this.options.args.labelCache;
        //重新渲染，监听itemLabelName的变幻
        if (manageMode) {
            var self = this;
            self.buttonTextRender = false;
            self.$nextTick(() => {
                self.buttonTextRender = true;
            });
            if (typeof Fai.top.labelModuleData === 'undefined') {
                Fai.top.labelModuleData = {};
            }
            if (!Fai.top.labelModuleData['module' + this.module.id]) {
                Fai.top.labelModuleData['module' + this.module.id] = this.options.args.childModuleList;
            }
        }
        this.showTab(this.currentIndex);
        removeJumpModuleInfo(this.module.id);
    },
    methods: {
        ...mapActions(['setModule']),
        addModuleToTab(labelId) {
            inTabModuleEdit.addModuleToTabByAddBtn(this.id, labelId, event);
        },
        formTabButtonClass(index, id) {
            var cls = '';
            cls += 'formTabButton' + id;
            if (this.currentIndex == index) {
                cls += ' g_label_active_label formTabButtonClick g_main_color_v3 g_oldThemeColor';
            } else {
                cls += ' g_label_deFault_label';
            }
            return cls;
        },
        formTabMiddleClass(index) {
            var cls = '';
            if (this.currentIndex == index) {
                cls += 'g_main_bgColor_v3 g_oldThemeBgColor';
                if (this.tabStyle == 1 && this.currentIndex == index && this.nlms.hls == 1) {
                    cls += ' g_main_sdColor_v3_1';
                }
                return cls;
            }
        },
        formTabContentClass(index) {
            var cls = '';
            if (this.currentIndex == index) {
                cls += 'formTabCntIdClick';
            } else {
                cls += 'f-hideTabModule';
            }
            return cls;
        },
        formTabMiddleStyle(index) {
            if (!manageMode) {
                return '';
            }
            var style = {},
                bgData,
                marginData = this.nlms.hlms,
                showShadow = this.nlms.hls;

            if (this.currentIndex == index) {
                bgData = this.nlms.hla.lbg;

                if (this.chooseColor != '') {
                    style['background-color'] = this.chooseColor;
                    if (showShadow == 1 && this.tabStyle == 1) {
                        style['box-shadow'] = '0 0.15rem 0.375rem 0 ' + color2Rgba(this.chooseColor, 0.4);
                    }
                }

                if (bgData && bgData.t == 1) {
                    style['background-color'] = bgData.c;
                    if (showShadow == 1 && this.tabStyle == 1) {
                        style['box-shadow'] = '0 0.15rem 0.375rem 0 ' + color2Rgba(bgData.c, 0.4);
                    }
                }
            } else {
                bgData = this.nlms.hld.lbg;
                if (bgData && bgData.t == 1) {
                    style['background-color'] = bgData.c;
                }
            }

            if (this.nlms.hll != 2 && marginData && marginData.t == 1) {
                var horizontalVal = marginData.h;
                var verticalVal = marginData.v;
                style['margin-left'] = horizontalVal + 'rem';
                style['margin-right'] = horizontalVal + 'rem';
                if (!this.wrapOpen) {
                    style['margin-top'] = verticalVal + 'rem';
                }
                style['margin-bottom'] = verticalVal + 'rem';
            }
            return style;
        },
        label_button_style(index) {
            if (!manageMode) {
                return '';
            }
            var style = {},
                fontSetData;

            if (this.currentIndex == index) {
                fontSetData = this.nlms.hla.ltf;
                if (this.tabStyle == 2 && this.chooseColor != '') {
                    style['color'] = this.chooseColor;
                }
            } else {
                fontSetData = this.module.content.mhighs.ltf;
            }

            if (fontSetData && fontSetData.type == 1) {
                if (fontSetData.nfs) {
                    style['font-size'] = fontSetData.nfs + 'rem';
                } else {
                    style['font-size'] = '12px';
                }
                if (fontSetData.nfct == 1) {
                    style['color'] = fontSetData.nfc;
                }
            }

            return style;
        },
        bottomLineStyle(index) {
            if (!manageMode) {
                return '';
            }
            var style = {},
                underLineData;
            if (this.currentIndex == index) {
                underLineData = this.nlms.hla.lu;
                if ((this.tabStyle == 2 || this.tabStyle == 3) && this.chooseColor != '') {
                    style['border-bottom-color'] = this.chooseColor;
                }
            } else {
                underLineData = this.nlms.hld.lu;
            }

            if (this.tabStyle == 2 && underLineData) {
                if (underLineData.t == 2) {
                    if (underLineData.w) {
                        style['border-bottom-width'] = underLineData.w + 'rem';
                    } else {
                        style['border-bottom-width'] = '1px';
                    }
                    if (underLineData.s == 0) {
                        style['border-bottom-style'] = 'solid';
                    } else if (underLineData.s == 1) {
                        style['border-bottom-style'] = 'dotted';
                    } else if (underLineData.s == 2) {
                        style['border-bottom-style'] = 'dashed';
                    }
                    style['border-bottom-color'] = underLineData.c;
                }
            }
            return style;
        },
        showUnderLine(index) {
            var underLineData;
            if (this.currentIndex == index) {
                underLineData = this.nlms.hla.lu;
                if (this.tabStyle == 3 && this.nlms.hlst == 1) {
                    return true;
                }
                if (this.tabStyle == 2 && underLineData.t != 1) {
                    return true;
                }
            } else {
                underLineData = this.nlms.hld.lu;
                if (this.tabStyle == 2 && underLineData.t != 1 && this.nlms.hlus == 1) {
                    return true;
                }
            }

            return false;
        },
        itemLabelName(labelName, itemId) {
            if (!manageMode) {
                return labelName;
            } else {
                if (labelName == '' && this.getModuleById(itemId)) {
                    return this.getModuleById(itemId).name;
                } else {
                    return labelName;
                }
            }
        },
        tabButtonClickHanle(index, $event) {
            if ($event.data) {
                $event.stopPropagation();
            }
            if (this.showLoading) {
                return;
            }

            // --bug=1064724 --user=陈奖 标签模块中不同标签项添加在线视频模块，不同标签中的模块可以同时播放 https://www.tapd.cn/42954297/s/1422307
            const videos = Array.from(jm(this.$refs.tabContentBox).find('video:not([autoplay])'));

            for (let video of videos) {
                if (!video.paused) {
                    video.pause();
                }
            }

            this.showTab(index);
        },
        showTab(index) {
            var currentLabel = this.module.content.labelList[index];
            var tabModuleId = currentLabel.id;
            if (!tabModuleId || tabModuleId < 0) {
                this.currentIndex = index;
                this.setContentHeight(true);
                return;
            }
            var _this = this;
            var cache = this.labelCache['module' + currentLabel.id];
            if (!cache) {
                _this.setContentHeight();
                _this.$nextTick(function () {
                    _this.currentIndex = index;
                    _this.activeModule(currentLabel, index);
                });
            } else {
                _this.currentIndex = index;
                setTimeout(function () {
                    _this.setContentHeight(true);
                }, 0);
            }
        },
        async activeModule(currentLabel) {
            var _this = this;
            var tabModuleId = currentLabel.id;
            this.showLoading = true;

            var dataQuery = {};
            dataQuery['_colId'] = Fai.top._colId;
            dataQuery['_extId'] = Fai.top._extId;
            dataQuery['moduleId'] = tabModuleId;
            dataQuery['style'] = currentLabel.style;
            dataQuery['qurl'] = getQurl();
            dataQuery['parentId'] = this.module.id;
            dataQuery['parentStyle'] = this.module.style;
            let result = await getChildModuleData(dataQuery);
            let { success, data } = result;

            if (success) {
                var moduleInfo = data.moduleInfo;
                var moduleHtml = data.domStr;
                var moduleJs = data.scripts;
                var tmpCache = {};
                tmpCache.moduleScript = moduleJs;
                tmpCache.sbm = moduleHtml;
                tmpCache.load = true;

                this.revalidateModule(moduleInfo);
                _this.$set(_this.labelCache, 'module' + moduleInfo.id, tmpCache);

                _this.$nextTick(() => {
                    const isThemeV3 = Fai.top._openThemeV3;
                    const isPack = moduleInfo.style == 51;
                    const isThemeV2VisitorPack = !isThemeV3 && !manageMode && isPack;
                    const isInTab = !!moduleInfo.inTab;
                    // fix自由容器在标签模块因为SSR激活高度属性因为节点替换丢失，这里重新单独设置一次
                    if (isThemeV2VisitorPack && isInTab) {
                        const moduleId = moduleInfo.id;
                        let $previewModule = jm('#module' + moduleId);

                        let patternHeight = 0;
                        if ($previewModule.length > 0) {
                            const heightTmp = parseInt($previewModule.attr('_height'));
                            if ($previewModule.attr('_autoHeight') == 0 && heightTmp > 0) {
                                patternHeight = heightTmp;
                            }
                        }
                        let initHeight = parseInt(patternHeight) || parseInt($previewModule.height()) || 1;
                        let height = initHeight > 2000 ? 2000 : initHeight;

                        let $module = jm('#module' + moduleId);
                        const heightType = +$module.attr('_autoHeight');

                        if (heightType === 0) {
                            const fontsize =
                                window.moduleManageFontSize && window.moduleManageFontSize['module' + moduleId]
                                    ? window.moduleManageFontSize['module' + moduleId]
                                    : NEW_MANAGE_HTML_FONTSIZE;
                            setModuleHeight2(moduleId, height, fontsize);

                            $module.attr({
                                _height: height,
                                _autoHeight: '0',
                            });
                        } else {
                            $module.css('height', 'auto');
                            let $formMiddles = $module.find('.formMiddle');
                            jm($formMiddles[0]).css('height', 'auto');
                            let $moduleMiddleContents = $module.find('.formMiddleContent');
                            let moduleMiddleContent = jm($moduleMiddleContents[0]);
                            moduleMiddleContent.css('height', 'auto');

                            $module.attr('_autoHeight', '1');
                        }
                    }
                    _this.showLoading = false;
                    _this.fixAnimate();
                });
            } else {
                ing(LS.js_systemError);
                _this.showLoading = false;
                Monitor.logAlarmMonitor(MonitorDef.AlarmMonitorId.TAB_CONTAINER_SWITCH);
            }
        },
        fixAnimate() {
            //设置刚加载的模块的高度
            this.setContentHeight(true);
        },
        setContentHeight(val) {
            if (val) {
                this.contentHeight = 'auto';
            } else {
                this.contentHeight = parseFloat(jm(this.$refs.tabContentBox).height()) + 'px';
            }
        },
        revalidateModule(moduleInfo) {
            let moduleId = moduleInfo.id;
            if (!this.getModuleById(moduleId)) {
                this.setModule({
                    moduleId: moduleId,
                    data: moduleInfo,
                });
            }
            if (VITE_APP_MODE !== 'visitor') {
                window.$store.commit('manage/updateModuleAttr', {
                    attr: initModuleAttr(moduleInfo.style, moduleInfo.pattern),
                    id: moduleInfo.id,
                });
            }
            if (Array.isArray(moduleInfo.children)) {
                for (let child of moduleInfo.children) {
                    this.revalidateModule(child);
                }
            }
        },
    },
};

function bindEvent(context) {
    initTabModuleStyle(context.id, context.labelLayout);
    bindJumpModuleEvent(context.id, context.module.style);
}

function color2Rgba(color, opacity = 1) {
    color = color.toLowerCase();
    // 如果是 16进制，直接转 rgba，透明度默认为 1
    if (color.indexOf('#') != -1) {
        var sColor = color;
        //十六进制颜色值的正则表达式
        var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
        // 如果是16进制颜色
        if (sColor && reg.test(sColor)) {
            if (sColor.length === 4) {
                var sColorNew = '#';
                for (var i = 1; i < 4; i += 1) {
                    sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
                }
                sColor = sColorNew;
            }
            //处理六位的颜色值
            var sColorChange = [];
            for (let i = 1; i < 7; i += 2) {
                sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
            }
            return 'rgba(' + sColorChange.join(', ') + ', ' + opacity + ')';
        }
    } else if (color.indexOf('rgba') != -1) {
        let colorArr = color
            .replace(/[()]/g, ',')
            .split(',')
            .map((item) => {
                return item.trim();
            });
        return colorArr[0] + '(' + colorArr[1] + ', ' + colorArr[2] + ', ' + colorArr[3] + ', ' + opacity + ')';
    } else if (color.indexOf('rgb') != -1) {
        // rgb 格式，添加透明度
        let colorArr = color
            .replace(/[()]/g, ',')
            .split(',')
            .filter((item) => {
                return item != '';
            })
            .map((item) => {
                return item.trim();
            });
        return 'rgba' + '(' + colorArr[1] + ', ' + colorArr[2] + ', ' + colorArr[3] + ', ' + opacity + ')';
    } else {
        throw new Error('not a color');
    }
}
</script>

<style lang="scss">
.fk-formTab {
    overflow: hidden;
    position: relative;
}
.fk-formTab .J_formTabModuleContent * {
    user-select: auto !important;
}
.fk-formTab .f-formTabModuleContent {
    overflow: hidden;
    position: relative;
    margin-top: 0.25rem;
}
.fk-formTab .f-formTabButtonWrap {
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 0.75rem;
} /*这边取150%增高遮挡滚动条*/
.fk-formTab .wrapOpen .f-formTabButtonWrap {
    height: auto;
}
.fk-formTab .f-formTabButton {
    display: inline-block;
    vertical-align: top;
}
.fk-formTab .f-formTabButtonList {
    width: 500%;
    font-size: 0.65rem;
    color: #666;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    transition: left 0.4s;
    display: flex;
}
.fk-formTab .f-formTabMiddle {
    cursor: default;
    max-width: 14rem;
    min-width: 0.7rem;
    max-height: 1.95rem;
    font-size: 0;
    text-align: center;
}
.fk-formTab .formTabCntIdClick {
    display: block;
}
.fk-formTab .formTabButtonHover {
}
.fk-formTab .formBannerTitle {
    display: none !important;
}
.fk-formTab .f-formTabContentList {
    position: relative;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    overflow: hidden;
}
.fk-formTab .f-preBtn {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.75rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 2rem;
}
.fk-formTab .f-nextBtn {
    position: absolute;
    right: 0;
    top: 0;
    width: 0.75rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 2rem;
}
.fk-formTab .f-preBtn:before {
    content: '\e60e';
    vertical-align: middle;
    color: #fff;
    font-size: 0.75rem;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 18;
}
.fk-formTab .f-nextBtn:before {
    content: '\e672';
    vertical-align: middle;
    color: #fff;
    font-size: 0.75rem;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 18;
}
.fk-formTab .form {
    border: none;
    margin: 0;
    padding: 0;
}
.fk-formTab .form .formBannerMore {
    display: none;
}
.fk-formTab .f-hideTabModule {
    width: 100%;
    position: absolute;
    top: 0;
    left: 150%;
}
.fk-formTab .f-formTabContent.showTabContent {
    display: block !important;
}
#g_body .f-formTabButtonList {
    color: #666;
}

.f-formTabButtonWrap::-webkit-scrollbar {
    display: none;
}
.f-formTabButtonWrap {
    -ms-overflow-style: none;
}
.f-formTabButtonWrap {
    overflow: -moz-scrollbars-none;
}

.fk-formTab .f-formTabMiddleBox {
    display: inline-block;
    text-align: center;
    max-width: 100%;
    font-size: 0;
}
.fk-formTab .f-formTabButtonText {
    display: inline-block;
    font-size: 0.6rem;
    word-break: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
}
/*样式1*/
.fk-tabStyle1 .f-formTabModuleContent {
    border: none;
}
.fk-tabStyle1 .f-formTabModuleContent.wrapOpen,
.fk-tabStyle2 .f-formTabModuleContent.wrapOpen {
    height: auto;
}
.fk-tabStyle1.fk-formTab .f-formTabButtonWrap {
    padding: 0 0.55rem;
}
.fk-tabStyle1 .f-formTabButton {
    height: auto;
}
.fk-tabStyle1 .f-formTabMiddle {
    margin: 0.48rem 0.15rem;
    padding: 0 0.65rem;
    border-radius: 0.625rem;
}
.fk-tabStyle1 .f-formTabButtonText {
    height: 1.25rem;
    line-height: 1.25rem;
    width: 100%;
}
.fk-tabStyle1 .formTabButtonClick .f-formTabButtonText {
    color: #fff;
}
.fk-tabStyle1 .formTabButtonClick.f-formTabButton {
    color: #fff;
}

.fk-tabStyle1.labelLayout3 .f-formTabMiddle {
    padding: 0 0.25rem;
}

/*样式2*/
.fk-tabStyle2 .f-formTabModuleContent {
    border: none;
    margin-top: 0.35rem;
}
.fk-tabStyle2 .f-formTabModuleContent.wrapOpen,
.fk-tabStyle2 .f-formTabModuleContent.wrapOpen {
    height: auto;
}
.fk-tabStyle2 .f-formTabButtonList {
}
.fk-tabStyle2 .f-formTabMiddle {
    background-color: unset !important;
    box-shadow: unset !important;
}
.fk-tabStyle2 .f-formTabMiddleBox {
    position: relative;
}
.fk-tabStyle2 .formTabButtonClick .f-formTabMiddle {
    position: relative;
}
.fk-tabStyle2 .f-formTabButtonUnderLine {
    border-radius: 0.1rem;
    width: 100%;
    display: inline-block;
    overflow: hidden;
}
.fk-tabStyle2 .f-formTabButtonUnderLine .buttonLine {
    display: inline-block;
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    width: 100%;
}
.fk-tabStyle2 .f-formTabButton:not(.formTabButtonClick) .f-formTabButtonUnderLine .buttonLine {
    border-bottom-color: transparent;
}
.fk-tabStyle2 .f-formTabButtonText {
    height: 1.45rem;
    line-height: 1.45rem;
    padding: 0 0.55rem;
}
.fk-tabStyle2 .f-formTabMiddleBox .f-formTabButtonUnderLine {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.fk-tabStyle2.longLine .f-formTabButtonWrap {
    padding: 0;
}

.fk-tabStyle2.shortLine .f-formTabButtonWrap {
    padding: 0 0.475rem;
}
.fk-tabStyle2.shortLine .f-formTabMiddle {
    margin: 0 0.5rem;
}
.fk-tabStyle2.shortLine .f-formTabButtonText {
    padding: 0;
}
.fk-tabStyle2.shortLine .f-formTabButtonUnderLine {
    width: calc(100% - 0.5rem);
    left: 0.25rem;
}

.fk-tabStyle2.longLine.labelLayout3 .f-formTabButtonList .f-formTabMiddleBox {
    width: 100%;
}
.fk-tabStyle2.longLine.labelLayout3 .f-formTabButtonList .f-formTabButtonText {
    box-sizing: border-box;
}
.fk-tabStyle2.longLine .style2DefaultLine {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0;
    display: inline-block;
}
.fk-tabStyle2 .style2DefaultLine .buttonLine {
    display: inline-block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: 100%;
    border-bottom-color: rgba(238, 238, 238, 1);
}

/*样式3*/
.fk-tabStyle3 .f-formTabModuleContent {
    border: none;
}
.fk-tabStyle3 .f-formTabModuleContent.wrapOpen,
.fk-tabStyle2 .f-formTabModuleContent.wrapOpen {
    height: auto;
}
.fk-formTab.fk-tabStyle3 .f-formTabButtonWrap {
    padding: 0;
}
.fk-tabStyle3 .f-formTabMiddle {
    margin: 0.25rem 0.75rem;
    background-color: unset !important;
    height: 1.25rem;
    line-height: 1.25rem;
    box-shadow: unset !important;
}
.fk-tabStyle3 .formTabButtonClick .f-formTabMiddle {
    position: relative;
}
.fk-tabStyle3 .f-formTabButtonUnderLine {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
}
.fk-tabStyle3 .f-formTabButtonUnderLine .buttonLine {
    display: inline-block;
    border-bottom-width: 0.2rem;
    border-bottom-style: solid;
    width: 100%;
}
.fk-tabStyle3 .f-formTabButton:not(.formTabButtonClick) .f-formTabButtonUnderLine .buttonLine {
    border-bottom-color: transparent;
}
.fk-tabStyle3 .f-formTabButton {
    color: #222222;
    vertical-align: text-bottom;
}
.fk-tabStyle3.fk-formTab .f-formTabMiddleBox {
    line-height: 1;
    position: relative;
}
.fk-tabStyle3 .f-formTabButtonText {
    vertical-align: middle;
    line-height: 1.2;
    z-index: 9;
}
.fk-tabStyle3 .formTabButtonClick .f-formTabButtonText {
    font-size: 0.75rem;
    font-weight: bold;
}
.fk-tabStyle3.labelLayout3 .f-formTabMiddle {
    margin: 0.25rem 0.5rem;
}

/*样式4*/
.fk-tabStyle4.fk-formTab .f-formTabModuleContent {
    margin: 0;
}
.fk-tabStyle4 .f-formTabModuleContent.wrapOpen,
.fk-tabStyle2 .f-formTabModuleContent.wrapOpen {
    height: auto;
}
.fk-tabStyle4.fk-formTab .f-formTabButtonList {
    background-color: #2e3746;
}
.fk-formTab.fk-tabStyle4 .f-formTabButtonWrap {
    padding: 0;
}
.fk-tabStyle4.fk-formTab .f-formTabMiddle {
    padding: 0 0.95rem;
    max-height: unset;
    box-shadow: unset !important;
}
.fk-tabStyle4.fk-formTab .f-formTabButton:not(.formTabButtonClick) .f-formTabMiddle {
    background-color: #2e3746;
}
.fk-tabStyle4 .f-formTabButton {
    font-weight: bold;
    vertical-align: text-bottom;
}
.fk-tabStyle4 .f-formTabButtonText {
    vertical-align: middle;
    height: 2rem;
    line-height: 2rem;
    color: #fff;
}
.fk-tabStyle4.labelLayout3 .f-formTabMiddle {
    padding: 0 0.25rem;
}

.fk-formTab.labelLayout1 .wrapOpen .f-formTabButtonList {
    height: auto;
    width: 100% !important;
    flex-wrap: wrap;
    white-space: wrap;
}
.fk-formTab.labelLayout2 .f-formTabButtonList {
    justify-content: center;
}
.fk-formTab.labelLayout3 .f-formTabMiddle {
    max-width: unset;
}

.fk-formTab.labelLayout3 .f-formTabButtonList {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100% !important;
}
.fk-formTab.labelLayout3 .f-formTabButtonList .f-formTabButton {
    min-width: 20%;
    flex: 1;
}
</style>
